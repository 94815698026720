<template>
  <p class="mt-auto pt-4 md:pt-8">
    When signing up you accept Footprint’s
    <a
      href="https://yourfootprint.co/app/terms"
      target="_blank"
      class="text-green-fp-2"
      >Terms and<br class="hidden md:block" />
      Conditions</a
    >
    as well as
    <a
      href="https://yourfootprint.co/app/privacy"
      target="_blank"
      class="text-green-fp-2"
      >Privacy Policy</a
    >.
  </p>
</template>
