import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55089489"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "content-wrapper divider-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicCloudinaryImg = _resolveComponent("DynamicCloudinaryImg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DynamicCloudinaryImg, {
      class: "img-wrapper",
      cloudinaryImgId: _ctx.cloudinaryImgId,
      useGradient: false
    }, null, 8, ["cloudinaryImgId"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["h-full flex flex-col", _ctx.useDefaultContainer ? 'max-w-xl mx-auto' : ''])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ])
  ]))
}