
import { defineComponent } from 'vue'

import DynamicCloudinaryImg from '@/components/molecules/DynamicCloudinaryImg'

export default defineComponent({
  components: {
    DynamicCloudinaryImg,
  },
  props: {
    cloudinaryImgId: {
      type: String,
      required: true,
    },
    useDefaultContainer: {
      type: Boolean,
      default: true,
    },
  },
})
